import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Treatments from "../components/treatments"

export default function OurTreatments() {
  return (
    <Layout>
      <SEO title="Our Treatments" description="" />
      <Treatments />
    </Layout>
  )
}
